<template>
  <div>
    <WaBtn />
    <header class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-banner.jpg')">
      <TopHeader />
      <MainBanner />
    </header>

    <!-- mobile enquiry -->
    <!-- <div class="py-10 lg:hidden">
      <div class="container md:max-w-xl">
        <div class="p-5 rounded-md bg-orange-1">
          <h1 class="text-2xl font-semibold text-center text-white">
            Sent Us An Enquire
          </h1>
          <div class="bg-white w-10 h-0.5 mx-auto my-3"></div>
          <EnquiryForm class="pt-3" />
        </div>
      </div>
    </div> -->

    <!-- about us-->
    <section class="py-10 lg:pt-10">
      <div class="container">
        <div class="lg:max-w-4xl lg:mx-auto">
          <p class="text-2xl font-semibold text-center text-orange-1">
            About Us
          </p>
          <p class="mt-4 text-sm leading-tight text-center text-gray-600">
            Unibest Carpets, was incorporated with the vision of being a leading
            commercial supplier in the flooring industry. Following the motto of
            our logo “BEST”, we are highly recommended Wholesalers & Importers
            in the flooring industry.
            <br /><br />
            We provide excellent carpet & flooring solutions to customers with
            consistently delivering highquality advice, offering a wide range of
            options, and excellent customer service. From the initial selection
            process to the final installation, our dedicated team provides
            personalized assistance and guidance, ensuring that each customer
            finds the perfect solution tailored to their specific needs.
            <br /><br />
            We are a one-stop solution for all your flooring requirements.
            Customer satisfaction and positive experiences are the key
            indicators of our effectiveness in this field.
          </p>
        </div>
        <div class="mt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in about_us" :key="i" class="relative w-2/3 mx-auto mb-8 lg:px-2 md:w-1/2 lg:w-1/4">
            <div class="p-6 mx-auto text-center text-white rounded-full w-52 h-52" :class="item.bgColor">
              <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto" />
              <p class="pt-5 leading-tight">
                {{ item.alt }}
              </p>
            </div>
            <div class="absolute w-24 -left-5 -top-4 md:left-8 lg:-left-5 xl:left-3">
              <img :src="'/images/' + item.number + '.png'" alt="" class="w-28" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- sub banner -->
    <!-- <section class="py-10 bg-green-1">
      <div class="container">
        <div class="md:flex md:items-center xl:max-w-5xl xl:mx-auto">
          <div class="mb-4 text-white md:w-1/2">
            <p class="pb-2 text-lg lg:text-3xl">Unibest Carpets & Flooring</p>
            <h1 class="text-4xl font-bold uppercase lg:text-6xl">
              IS WHERE
              <span class="text-yellow-1">COMFORT</span> MEETS STYLE
            </h1>

            <div class="mt-6">
              <p
                class="w-64 py-1 font-medium text-center text-white rounded-md bg-red-1"
              >
                <a href="https://wa.me/60183126883">GET FREE QUOTE TODAY!</a>
              </p>
            </div>
          </div>
          <div class="mt-10 shadow-md md:w-1/2 lg:w-1/3 lg:mx-auto">
            <img src="/images/banner-1.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </section> -->

    <!-- product  -->
    <section class="py-10 bg-gray-50">
      <div class="container">
        <p class="text-2xl font-semibold text-center text-orange-1">
          Our Products
        </p>
        <div class="bg-orange-1 w-12 h-0.5 mx-auto my-3"></div>
        <div class="pt-4">
          <p class="text-sm leading-tight text-center text-gray-600">
            With a meticulous focus on craftsmanship, durability, aesthetics,
            and customer satisfaction, our carpets embody the perfect blend of
            functionality and elegance. To achieve the pinnacle of carpet
            excellence, we insist on using only the finest quality materials.
            Each carpet is crafted from carefully selected fibers that are not
            only soft and luxurious to the touch but also resilient and
            resistant to wear. We believe that our success lies in the smiles of
            our customers, and we strive to surpass their expectations at every
            turn.
          </p>
        </div>

        <div class="pt-6 md:flex lg:justify-center md:flex-wrap">
          <div v-for="(item, i) in product" :key="i" class="pb-6 md:w-1/2 lg:w-1/3 md:px-1">
            <div
              class="p-4 transition duration-300 ease-linear border-2 border-transparent shadow-sm hover:border-orange-1">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
              <div class="pt-4 text-center text-gray-600">
                <p class="text-lg font-semibold lg:text-base">{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- why choose us -->
    <div class="py-10 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <div class="md:w-4/5 md:mx-auto lg:w-5/6">
          <div class="p-5 text-center bg-orange-1 bg-opacity-80">
            <h1 class="text-2xl font-semibold text-white md:text-3xl">
              Why Choose Us
            </h1>
            <div class="bg-white w-12 h-0.5 mx-auto my-3"></div>
            <div class="mt-7 md:flex md:flex-wrap">
              <div v-for="(item, i) in choose_us" :key="i" class="w-2/3 pb-4 mx-auto md:w-1/2 lg:w-1/3 md:p-2">
                <div
                  class="p-5 text-center text-white transition duration-200 ease-linear border-2 border-white hover:bg-green-1 hover:border-green-1">
                  <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto" />
                  <div class="pt-3 text-sm md:h-20">
                    <p class="leading-tigh font-mediumt">{{ item.alt }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-10 bg-orange-1">
      <div class="container lg:flex lg:items-center">
        <div class="text-center lg:w-1/2 lg:text-left">
          <p class="text-3xl font-semibold leading-tight text-white">
            Broadloom Carpet Specialist
          </p>
          <div class="bg-white w-12 h-0.5 lg:mx-0 mx-auto my-3"></div>
          <div class="mt-5 font-medium leading-tight text-white">
            <p>We are the largest carpet manufacturer & supplier in Malaysia for the <span class="font-bold">Big Size Project</span>. We
              provide wide width, typically spanning from wall to wall in a room without the need for seams or
              joins. It is a <span class="font-bold">large, industrial-grade loom</span> capable of producing carpets, we back you up for
              <span class="font-bold">Hotel, Exhibition, Wedding or Custom-Made Carpet</span>.</p>
            <div class="mt-5">
              <p class="py-2 mx-auto text-center text-gray-900 bg-white rounded-lg lg:mx-0 w-52">
                <a v-scroll-to="{ el: '#element', duration: 1500 }" href="#">Send in your Enquiry</a>
              </p>
            </div>
          </div>
        </div>

        <div class="mt-8 lg:w-1/2 lg:mt-0">
          <img src="/images/product-1.jpg" alt="" class="mx-auto">
        </div>
      </div>
    </div>

    <Testimonials />

    <!-- enquiry -->
    <div class="py-10 bg-orange-1">
      <div class="container">
        <div id="element" class="p-5 bg-white rounded-xl lg:max-w-xl lg:mx-auto">
          <div class="py-5 text-center">
            <h1 class="text-2xl font-bold text-orange-1">Send Us An Enquiry</h1>
            <div class="bg-orange-1 w-10 h-0.5 mx-auto my-3"></div>
          </div>
          <EnquiryForm />
        </div>
      </div>
    </div>

    <!-- footer -->
    <section id="enquiry" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')">
      <div class="container">
        <div class="pt-5">
          <img src="/images/main-logo.png" alt="" class="w-2/3 mx-auto md:w-52" />
          <div class="pt-3 text-center text-gray-800">
            <div class="pb-5">
              <h1 class="pb-2 text-xl font-bold">Unibest Carpet</h1>
              <!-- <p class="text-base font-normal leading-tight md:text-xl">
                Block K-2-10, Solaris Mont Kiara, 50480 Kuala Lumpur
              </p> -->

              <div class="pt-5">
                <p class="font-bold">WhatsApp Us</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1">
                  <a href="https://wa.me/60136924243 " class="">013-692 4243 </a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-yellow-1">
                  Mobile : <a href="tel:+60136924243 ">013-692 4243 </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-3 bg-orange-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </section>

    <StickyBottom />
  </div>
</template>

<script>
// @ is an alias to /src
import TopHeader from "@/components/TopHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import Testimonials from "@/components/Testimonials";
import WaBtn from "@/components/WaBtn.vue";
import StickyBottom from "@/components/StickyBottom.vue";

export default {
  name: "Home",
  components: {
    TopHeader,
    MainBanner,
    EnquiryForm,
    Testimonials,
    WaBtn,
    StickyBottom,
  },
  data() {
    return {
      about_us: [
        {
          bgColor: "bg-orange-1",
          image: "choose-1",
          alt: "Call Us For Quote",
          number: "number-1",
        },
        {
          bgColor: "bg-green-1",
          image: "choose-2",
          alt: "On Site Measurement",
          number: "number-2",
        },
        {
          bgColor: "bg-yellow-1",
          image: "choose-3",
          alt: "Concept Design Consultation",
          number: "number-3",
        },
        {
          bgColor: "bg-red-1",
          image: "choose-4",
          alt: "Process Order and Done",
          number: "number-4",
        },
      ],
      product: [
        { image: "product-5", alt: "Office Carpet" },
        { image: "product-1", alt: "Broadloom Carpet" },
        { image: "product-9", alt: "Carpet Tiles" },
        { image: "product-8", alt: "Masjid/Surau Carpet" },
        { image: "product-2", alt: "Hotel/Exhibition Carpet" },
        { image: "product-11", alt: "SPC/Vinyl Flooring" },
        // { image: "product-3", alt: "Wedding Carpet" },
        // { image: "product-4", alt: "Hotel Carpet" },
        // { image: "product-6", alt: "Custom Made" },
        // { image: "product-7", alt: "Carpet Mekkah" },
        // { image: "product-10", alt: "Artificial Grass" },
        // { image: "product-11", alt: "SPC Flooring" },
        // { image: "product-12", alt: "Vinyl Flooring" },
      ],
      choose_us: [
        {
          image: "icon-1",
          alt: "Cheapest Guaranteed in the Market",
        },
        {
          image: "icon-2",
          alt: "Widest Product Selection",
        },
        {
          image: "icon-3",
          alt: "Direct Manufacturer Supply Globally",
        },
        {
          image: "icon-4",
          alt: "Quality Assurance & Warranty Certificates",
        },
        {
          image: "icon-5",
          alt: "More than 45 years experience",
        },
        { image: "icon-6", alt: "99% Customer Satisfaction" },
      ],
    };
  },
};
</script>
