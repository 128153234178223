<template>
  <div class="container fixed bottom-0 left-0 right-0 z-50 mx-auto transition-all duration-700" :class="isOnTop?'-mb-20':'mb-0'">
    <div class="p-4 font-bold text-center text-white rounded-t-full bg-red-1">
      <a  v-scroll-to="{ el: '#element', duration: 1500 }" href="#">GET FREE QUOTE TODAY!</a>
    </div>
  </div>
</template>

<script>
export default {

  mounted () {
    document.addEventListener('scroll', this.scrollHandler)
  },
  data() {
    return {
      isOnTop: true
    };
  },
  methods: {
    scrollHandler(){
      this.isOnTop = window.pageYOffset <= 100;
    }
  },
};
</script>

<style scoped>

</style>
