<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-banner.jpg')"
  >
    <div class="container">
      <div class="py-40 md:py-48">
        <div
          class="text-center md:border-white md:shadow-md md:w-4/5 md:p-6 md:mx-auto md:border-4"
        >
          <h1
            class="text-3xl font-bold leading-tight text-white md:text-4xl text-shadow xl:text-5xl"
          >
          Malaysia No.1 Carpet & Flooring <br class="hidden"> Manufacturer and Supplier
          </h1>
          <div class="bg-white w-12 h-0.5 mx-auto my-3 shadow-lg"></div>
          <p
            class="mt-4 font-semibold leading-tight text-white xl:text-xl text-shadow md:text-lg"
          >
          Established since 1977 <br>
          Quality & Best Price Guaranteed | Direct Manufacturer 
          </p>
        </div>
        <!-- cta-button -->
        <div v-if="!hideEnquiry" class="mt-4">
          <p
            tabindex="0"
            class="py-1 mx-auto font-medium text-center text-gray-900 transition duration-200 ease-linear transform rounded-md hover:scale-110 hover:bg-yellow-500 w-52 bg-yellow-1"
          >
            <a href="https://wa.me/60136924243 ">Get A Free Quote</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import EnquiryForm from "@/components/EnquiryForm.vue";

export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  // components:{
  //   EnquiryForm
  // }
};
</script>

<style>
.text-shadow {
  text-shadow: 2px 2px 4px #242424;
}
</style>
